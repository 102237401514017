div#menu{
    background-color: beige;
    text-align: center!important;
}

div#menu a.nav-link{
    color: #115da9!important;
    cursor: pointer;
}

div#menu a.nav-link:hover{
    color: #115da9!important;
    font-weight: bolder;
}